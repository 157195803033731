<template>
  <details-banner-layout>
    <!-- task icon -->
    <template v-slot:avatar>
      <new-task-icon :task="task"></new-task-icon>
    </template>

    <!-- task name -->
    <template v-slot:default>
      {{ title }}
    </template>

    <!-- task subtitle -->
    <template v-slot:subtitle>
      <div v-html="subtitle" :style="wrappedText"></div>
    </template>

    <template v-slot:actions>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            right
            v-on="on"
            v-show="!isValidTask"
            :color="colorNotValidEntriesClass"
            >{{ iconWarning }}</v-icon
          >
        </template>
        <span>{{ taskValidationMessage }}</span>
      </v-tooltip>
    </template>
  </details-banner-layout>
</template>

<script>
// model
import {
  findTaskPriority,
  isValidTaskEntries
} from "@/model/workflow/task/taskModel";
import { colorRecordDeletedClass } from "@/model/record/recordModel";

// design
import { iconWarning } from "@/design/icon/iconConst";
import { wrappedText } from "@/design/styles/styleConst";

export default {
  name: "NewTaskBanner",
  components: {
    DetailsBannerLayout: () =>
      import("@/components/shared/core/banners/DetailsBannerLayout"),
    NewTaskIcon: () => import("@/components/task/new/NewTaskIcon.vue")
    // TaskStatus: () => import("@/components/task/TaskStatus")
  },
  data() {
    return {
      iconWarning: iconWarning,
      colorNotValidEntriesClass: colorRecordDeletedClass,
      /**
       * wrapped Text style
       * which allows the text to wrap and take up multiple lines.
       * @type {string}
       */
      wrappedText: wrappedText
    };
  },
  props: {
    /**
     * @type {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee:string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole:string, canReassign:boolean, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone:boolean, isOverdue:boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled:boolean, workflowDefinitionId:number, notes: {id:number, taskId:number, text:string, userId:number, userName:string, created:string}[]}}
     */
    task: undefined
  },
  computed: {
    /**
     * task Name
     * @return {*|string}
     */
    taskName() {
      return this.task?.name ?? "";
    },

    /**
     * task title
     * @return {*|string}
     */
    title() {
      return this.taskName ?? "?";
    },

    /**
     * subtitle
     * @return {string|undefined}
     */
    subtitle() {
      const assignee = this.task?.assignment?.assignee;
      const assignment =
        this.task?.assignment?.fixed ?? false ? "Fixed assigned" : "Assigned";
      const priorityText = findTaskPriority(this.task?.priority)?.text;

      return assignee
        ? `
    <span>${assignment} to <span style="font-weight: bold;">${assignee}</span> with <span style="font-weight: bold;">${priorityText}</span> priority</span>
  `
        : `
    <span><span style="font-weight: bold;">Not assigned!</span></span>
  `;
    },

    /**
     * is Valid Task
     * @return {boolean}
     */
    isValidTask() {
      return isValidTaskEntries(this.task);
    },

    /**
     * Task Validation Message
     * @return {string}
     */
    taskValidationMessage() {
      return "Not Valid Task Entries";
    }
  }
};
</script>
